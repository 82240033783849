$tableBackgroundColor: #D3D3D3;
@import '../../../../constants/css-constants.scss';


.tableRoot {
  width: 100%;
  height: 100%;
}

.tabsContainer {
  min-height: 10%;
  margin-bottom: 10px;
}

.tab {
  min-width: initial !important;
}

.tableContainer {
  padding: 5px;
  width: 100%;
  max-height: 90%;
  background-color: $tableBackgroundColor;
  overflow: auto;
}

.tableRow {
  cursor: pointer !important;
}