@import '../../../constants/css-constants.scss';

.root {
    margin-right: 20px;
    width: $sidebar-width;
}

.tabSeparator, .sidebarItem, .sidebarSeparator {
    width: $sidebar-width;
    height: 1.5em;
    padding: 1.4em 0.5rem;
    margin-bottom: 0.5em;
}

.sidebarSeparator {
    max-width: $sidebar-width;
    overflow: hidden;
    height: 4.5em;
}

.sidebarItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: black;
    text-decoration: none;
    border-radius: 10px;

    &:hover {
        background-color: $hover-grey;
    }
}

.linkActive {
    background-color: $primary-color !important;
    color: white;

    .arrowIcon {
        display: initial !important;
    }
}

.sidebarIcon {
    display: flex;
    column-gap: 0.7em;
    align-items: center;
}

.arrowIcon {
    display: none !important;
}

.userBadgeContainer {
    margin-left: -5px;
}
