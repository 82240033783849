.avatar {
  background-color: #bdbdbd !important;
  overflow: hidden;

  &:hover .uploadButton {
    opacity: 0.7;
  }
}

.avatarContainer {
  position: relative;

  
  &:hover {
    .uploadButton {
      opacity: 0.7;
    }
  }
}

.uploadButton {
  position: absolute;
  bottom: 1em;
  height: 1.1em;
  display: flex;
  justify-content: center;
  opacity: 0;
  color: black;
  font-weight: bold;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}
.upload {
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.originalInput {
  display: none;
}

img {
  display: block;
  max-width: 100%;
}

.cropper-view-box {
	border-radius: 50%;
}
.cropper-face {
	background-color:inherit !important;
}

.saveButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0;
}
