@import '../../../constants/css-constants.scss';

.cardContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 310px 310px 310px 310px;
  row-gap: 60px;
  padding: 5px;

  @include md {
    padding-top: 10%;
    grid-template-columns: 45% 45%;
    grid-template-rows: 200px 200px;
    column-gap: 10%;
  }
}

.cardContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activitiesCard {
  @extend .cardContent;
  @include sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.welcomeCard {
  @extend .cardContent;
  width: 100%;
  height: 100%;
  background-color: #7f53ac;
  background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
  margin: 0 !important;
  color: white;
  flex-direction: column;

  .line {
    width: 70px;
    border-bottom: 3px solid white;
    border-radius: 5px;
  }
}

.missingData {
  font-size: 12px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.activitiesContainer {
  height: 100%;
  width: 100%;
}

.activityContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.activity {
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 25px 30px;
    display: inline-flex;
    flex-direction: row;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
    transition: all .3s;
    width: 100%;
}
.activity:hover {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
}

.root {
  display: flex;
  width: 100%;
}

.left {
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  gap: 30px;
}