.file {
  border: 1px solid #e6e6e6;
  padding: 10px 15px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  width: 250px;
  height: 65px;
  align-items: center;
  background-color: white;
  overflow: hidden;

  &:hover {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);   
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);

    .button {
        opacity: 1;
    }
  }
}

.uploadButton {
    @extend .file;
    width: initial;
    cursor: pointer;
}

.container {
    max-height: 250px;
    overflow: auto;
}

.fileName {
    max-width: 200px;
}

.button {
    transition: all 0.3s;
    opacity: 0;
    color: grey !important;
    cursor: pointer;

    &:hover {
        color: black !important;
    }
}

.uploadButton:hover {
    display: flex;
    .button {
        opacity: 1;
    }
}