@import '../../../constants/css-constants.scss';

.container {
  // Center content
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  @include md {
    margin-top: 0;
  }
}
