@import '../../../constants/css-constants.scss';

.cardRoot {
  height: 100%;
  width: 60vw;
}

.cardHeader {
  width: 100%;
  height: 70px;
  background-color: $primary-color;
  border-radius: 5px 5px 0 0;
  color: white;
  position: relative;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cardTitleActions {
  position: absolute;
  top: 16px;
  right: 5px;
}

.cardContent {
  background-color: white;
  padding: 60px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.05), 0 3px 6px rgba(0,0,0,0.23);
}

.card {
  width: 100%;
  height: 100%;
  margin: 0;
}

.cardPaper {
  overflow: auto !important;
  padding-top: 10px;
}
