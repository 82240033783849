.companySummary {
    margin-top: 2em;
}

.companyData {
    width: 100%;
    justify-content: space-between;
}

.banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;
}

.companyField {
    color: black !important;
    border-color: black !important;
    margin-bottom: 1.5em;
}

.multiFieldLine {
    display: flex;
    justify-content: space-between;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.fileUploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    -ms-flex-align: center;
    -moz-animation: alternate;
    animation: alternate;
    overflow: hidden;  
}

.avatar {
    position: relative;
}

.uploadButton {
    position: absolute;
    bottom: 1em;
}