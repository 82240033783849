.editRunningContainer {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.runningTrackerContainer {
  padding: 10px 5px;
  border-bottom: 2px solid black;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.runningTrackerDisplay {
  display: flex;
  align-items: center;
}

.trackerContainer {
  width: 100%;
  font-size: 1.5em;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.newTrackerEntry {
  background-color: rgba(#695ee8, 0.05);
  border: solid 2px #695ee8;
  padding: 25px 30px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  width: 100%;
}

.newEntryButtons {
  display: flex;
  column-gap: 15px;
}

.newEntryTotal {
  margin-top: 20px;
  margin-bottom: 25px;
}

.startEntryButton {
  margin-left: 5px !important;
}

.trackerEntriesContainer {
  margin-top: 10px;
}

.date {
  margin-bottom: 10px;
}

.edit {
  cursor: pointer;
}

.clearIcon:hover {
  color: red;
}

.trackerEntry {
  border: 1px solid grey;
  padding: 25px 30px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  width: 100%;
  align-items: center;

  &:hover {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.entryGrid {
  column-gap: 40px;
}

.trackerDateEntry {
  margin-bottom: 40px;
}

.dateAndDuration {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.confirmationButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
  margin-top: 20px;
}

.addButton {
  width: 100%;
}
