.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
