.root {
  width: 100%;
  height: 100%;
}

.calendarContainer {
  width: 100%;
  height: 100%;
}

.event {
  cursor: pointer !important;
}
