.UserBadge {
  display: flex;
  align-items: center;
  max-width: 250px;
  padding: 10px;
}

.nameBox {
  margin-left: 5px;
  word-wrap: break-word;
}

.arrowClosed {
  transform: rotate(0deg);
}

.arrowOpen {
  transform: rotate(180deg);
}
