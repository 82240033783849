.cardTitleContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  