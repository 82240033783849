@import '../../constants/css-constants.scss';

body {
  font-family: 'Avenir Next W01,Helvetica,Arial,sans-serif';
}

.protectedContent {
  width: $app-width;
  display: flex;
  flex: 1 0 auto;
  margin: 0 auto;
  column-gap: 10px;
  align-content: stretch;
  flex-direction: row;
  padding: 20px;
}

.unprotectedContent {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .content {
//   flex-grow: 1;
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   // @include landscape{
//   //   margin-top: $appbar-height-mobile-landscape;
//   // }

//   @include md {
//       height: 100%;
//       margin-top: 0;
//   }

// }
