.card {
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding: 20px;
  width: 310px;
}

.cardRoot {
  width: 310px;
  height: 240px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.cardTitle {
  width: 100%;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-bottom: 1px solid #D3D3D3;
  padding: 20px;
  text-transform: uppercase;
  align-content: center;
}

.cardContent {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}