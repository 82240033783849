.cardTitle {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.periodContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}