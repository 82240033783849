.cardContainer {
  top: '50%';
  left: '50%';
  transform: translate(-50%, -50%); 
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.inputContainer {
  width: 47%;
  display: flex;
}

.inlineFormGroup {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.input {
  width: 100%;
}

.buttonContainer {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
