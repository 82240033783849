.inlineFormInput {
    display: flex;
    flex-direction: column !important;
}

.inputContainer {
    margin-bottom: 1em;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
