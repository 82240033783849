$sm-size: 600px;
$md-size: 960px;
$lg-size: 1280px;
$xl-size: 1920px;

$appbar-height-mobile-portrait: 8vh;
$appbar-height-mobile-landscape: 15vh;

$app-width: 1230px;
$sidebar-width: 12em;

$primary-color: #695ee8;
$hover-grey: #d3d3d3;

@mixin sm() {
    @media print, screen and (min-width: $sm-size) {
        @content;
    }
}

@mixin md() {
    @media print, screen and (min-width: $md-size) {
        @content;
    }
}

@mixin lg() {
    @media print, screen and (min-width: $lg-size) {
        @content;
    }
}

@mixin xl() {
    @media print, screen and (min-width: $xl-size) {
        @content;
    }
}

@mixin landscape() {
    @media (orientation: landscape) {
        @content;
    }
}
