.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .buttons {
    width: 50%;
    display: flex;
    justify-content: space-between;
  }
}

.statusContainer {
  margin-top: 20px;
}

.timeOffDetailsContainer {
  display: block;
  width: 100%;
}