@import '../../../constants/css-constants.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.extraActionsContainer {
    margin-top: 20px;

    .extraActions {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .extraActionButton {
            margin-left: 5px;
            cursor: pointer;
        }
    }
}
