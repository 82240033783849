.modal {
  display: flex;
  justify-content: center;
  overflow: auto;

  @media only screen and (min-height: 650px) { 
    align-items: center;
  }
}

.modalTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardContainer {
  outline: none;
}
