.container {
  cursor: pointer;
  border: 1px solid #e6e6e6;
  padding: 25px 30px;
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
  transition: all .3s;
  width: 100%;
  align-items: center;
}
.container:hover {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
}
.container .calendarItem {
  text-align: center;
  background-color: #ecf3ff;
  padding: 5px 20px;
  color: #000;
  transition: all .3s;
}
.container .to {
  padding: 0 15px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  line-height: 12px;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
}
.container:hover .calendarItem{
  background-color: #266fee;
  color: #fff;
}
/*.container .calendarItem:last-child {
  margin-left: 20px;
}*/
.container .calendarItem .month {
  font-weight: 300;
  font-size: 12px;
}
.container .calendarItem .day {
  font-weight: bold;
  font-size: 22px;
}
.container .timeOffInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.container .timeOffInfo .timeOffType {
  font-size: 18px;
  font-weight: bold;
}
.container .timeOffInfo .timeOffDuration {
  font-size: 14px;
  font-weight: 300;
}

.extraActionButton {
  margin-left: auto;
}