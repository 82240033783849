.userSummary {
    gap: 10px;
    display: flex;
}

.profileData {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profileField {
    display: flex;
    align-items: center;
    line-height: 1.8em;
}

.profileFieldName {
    margin-right: 5px;
}

.resendInvitationButton {
    margin-left: 10px !important;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}