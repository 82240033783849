@import '../../../constants/css-constants.scss';

.container {
  display: flex;
  justify-content: center;
}

.card {
  width: 100vw;
  height: 100vh;
  margin: 0;

  @include md {
      width: 40vw;
      height: 70vh;
      margin: 15vh;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
