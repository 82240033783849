@import '../../../../constants/css-constants.scss';

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    
    @include lg {
        width: 70%;
    }
}

.textInput {
    width: 100%;
}

.buttonContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logInButton {
    margin-top: 5px !important;
}