.descriptionBox {
  width: 100%;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 5px;
  outline: none;
}

.buttonSelected {
  background-color: #757de8 !important;
}

.buttonContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputLabel {
  margin-bottom: 5px;
}

.formRow {
  margin-bottom: 20px;
  flex-direction: column;

  .fullLengthField {
    width: 100%;
  }  
}

.inlineForm {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .inlineFormInput {
    display: block;
    width: 45%;
  }
}

.errorDisplay {
  margin-top: 10px;
}
