@import '../../../../constants/css-constants.scss';

.summary {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.summaryContainer {
    display: flex;
    justify-content: space-between;
    
    &:hover {
        .editIcon {
            display: initial;
        }
    }

    @include sm {
        padding-left: 3em;
        padding-right: 3em;
    }
}

.upcomingTimeOff {
    margin-top: 5em !important;
}

.editIcon {
    display: none;
    position: absolute;
    cursor: pointer;
}

.timeOffDaysRemainingInput {
    width: 30px;
}

.actionsContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.periodContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 10px;
}
