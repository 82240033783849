.timeOffContainer {
  max-height: 70%;
  overflow: auto;
}

.tabsContainer {
  min-height: 10%;
  margin-bottom: 10px;
}

.tab {
  min-width: initial !important;
}

.title {
  display: flex;
  justify-content: space-between;
}