.checkBoxContainer {
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    width: 20%;
}